import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { Hash } from 'lucide-react';
import MessageList from "./MessageList";
import { ChatInput } from "./ChatInput";
import { RoomList } from "./RoomList";
import { UserSidebar } from "./UserSidebar";

export const ChatInterface = ({ user, settings }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageHistory, setMessageHistory] = useState({
        rooms: {
            General: [],
            Gaming: [],
            Music: []
        },
        privateChats: new Map()
    });
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [currentRoom, setCurrentRoom] = useState('General');
    const [selectedUser, setSelectedUser] = useState(null);
    const [privateMessage, setPrivateMessage] = useState('');
    const [unreadMessages, setUnreadMessages] = useState({});
    const [expandedSections, setExpandedSections] = useState({
        rooms: true,
        directMessages: true
    });
    const [ignoredUsers, setIgnoredUsers] = useState(() => {
        // Initialize from localStorage
        const saved = localStorage.getItem('ignoredUsers');
        return saved ? new Set(JSON.parse(saved)) : new Set();
    });
    const [joinedRooms] = useState(new Set(['General']));
    const socket = useRef(null);
    const messagesEndRef = useRef(null);
    const rooms = ['General', 'Gaming', 'Music'];

    // Save ignored users to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('ignoredUsers', JSON.stringify([...ignoredUsers]));
    }, [ignoredUsers]);

    useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.removeItem('user');
            window.location.reload();
        }, 300000);

        const handleBeforeUnload = () => {
            localStorage.removeItem('user');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!user) return;

        socket.current = io(process.env.REACT_APP_SERVER_URL);
        socket.current.emit('join', { username: user.username, room: currentRoom });

        socket.current.on('message', (message) => {
            if (message.type === 'system' &&
                message.text.includes('has joined the chat') &&
                joinedRooms.has(message.room)) {
                return;
            }
            setMessageHistory(prev => ({
                ...prev,
                rooms: {
                    ...prev.rooms,
                    [message.room]: [...(prev.rooms[message.room] || []), message]
                }
            }));
        });

        socket.current.on('privateMessage', (message) => {
            if (ignoredUsers.has(message.from)) return;

            const chatId = getChatId(message.from, message.to);

            setMessageHistory(prev => {
                const updatedPrivateChats = new Map(prev.privateChats);
                const existing = updatedPrivateChats.get(chatId) || [];
                updatedPrivateChats.set(chatId, [...existing, message]);

                return {
                    ...prev,
                    privateChats: updatedPrivateChats
                };
            });

            if (message.from !== user.username) {
                setUnreadMessages(prev => ({
                    ...prev,
                    [message.from]: (prev[message.from] || 0) + 1
                }));
            }
        });

        socket.current.on('updateUsers', (users) => {
            setOnlineUsers(users.filter(u => u !== user.username));
        });

        return () => {
            socket.current.disconnect();
        };
    }, [user.username, currentRoom, ignoredUsers , joinedRooms]);

    const handleIgnoreUser = (username) => {
        setIgnoredUsers(prev => {
            const newIgnored = new Set(prev);
            newIgnored.add(username);
            return newIgnored;
        });
        // If currently chatting with ignored user, reset selection
        if (selectedUser === username) {
            setSelectedUser(null);
            setCurrentRoom('General');
        }
    };

    const handleUnignoreUser = (username) => {
        setIgnoredUsers(prev => {
            const newIgnored = new Set(prev);
            newIgnored.delete(username);
            return newIgnored;
        });
    };

    const isUserIgnored = (username) => {
        return ignoredUsers.has(username);
    };

    const handleSendMessage = (e, formattedMessage) => {
        e.preventDefault();

        if (formattedMessage.text.trim()) {
            if (selectedUser) {
                // Don't send if user is ignored
                if (isUserIgnored(selectedUser)) {
                    setPrivateMessage('');
                    return;
                }

                const messageData = {
                    text: formattedMessage.text,
                    from: user.username,
                    to: selectedUser,
                    time: new Date().toLocaleTimeString(),
                    timestamp: Date.now(),
                    isPrivate: true,
                    isBold: formattedMessage.isBold,
                    isItalic: formattedMessage.isItalic
                };

                socket.current.emit('privateMessage', messageData);
            } else {
                const messageData = {
                    text: formattedMessage.text,
                    sender: user.username,
                    room: currentRoom,
                    time: new Date().toLocaleTimeString(),
                    timestamp: Date.now(),
                    isBold: formattedMessage.isBold,
                    isItalic: formattedMessage.isItalic
                };

                socket.current.emit('message', messageData);
            }

            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);

            setMessage('');
            setPrivateMessage('');
        }
    };

    // Rest of your existing methods...
    const handleRoomChange = (newRoom) => {
        if (currentRoom !== newRoom) {
            if (!joinedRooms.has(newRoom)) {
                socket.current.emit('changeRoom', {
                    username: user.username,
                    newRoom,
                    oldRoom: currentRoom
                });
                joinedRooms.add(newRoom);
            } else {
                socket.current.emit('switchRoom', {
                    username: user.username,
                    newRoom,
                    oldRoom: currentRoom
                });
            }
            setCurrentRoom(newRoom);
            setSelectedUser(null);
        }
    };

    const handleUserSelect = (selectedUsername) => {
        if (!isUserIgnored(selectedUsername)) {
            setSelectedUser(selectedUsername);
            setCurrentRoom(null);
            setUnreadMessages(prev => ({
                ...prev,
                [selectedUsername]: 0
            }));
        }
    };

    const getChatId = (user1, user2) => {
        return [user1, user2].sort().join('-');
    };

    const getCurrentMessages = () => {
        if (selectedUser) {
            const chatId = getChatId(user.username, selectedUser);
            return messageHistory.privateChats.get(chatId) || [];
        }
        return messageHistory.rooms[currentRoom] || [];
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const getActiveDirectMessageUsers = () => {
        const activeUserSet = new Set();
        messageHistory.privateChats.forEach((messages, chatId) => {
            const [user1, user2] = chatId.split('-');
            if (user1 !== user.username && !ignoredUsers.has(user1)) activeUserSet.add(user1);
            if (user2 !== user.username && !ignoredUsers.has(user2)) activeUserSet.add(user2);
        });

        return onlineUsers.filter(username => !ignoredUsers.has(username) && activeUserSet.has(username));
    };

    return (
        <div className="flex w-full h-screen">
            <RoomList
                rooms={rooms}
                currentRoom={currentRoom}
                expandedSections={expandedSections}
                onRoomChange={handleRoomChange}
                onToggleSection={toggleSection}
                onlineUsers={getActiveDirectMessageUsers()}
                selectedUser={selectedUser}
                unreadMessages={unreadMessages}
                onUserSelect={handleUserSelect}
                username={user.username}
                ignoredUsers={ignoredUsers}
                onIgnoreUser={handleIgnoreUser}
                onUnignoreUser={handleUnignoreUser}
            />

            <div className="flex-1 flex flex-col bg-white overflow-hidden">
                <div className="p-4 border-b bg-white shadow-sm">
                    <h2 className="font-bold text-lg flex items-center">
                        {selectedUser ? (
                            <>
                                <div className="w-2 h-2 bg-green-500 rounded-full mr-2"/>
                                @{selectedUser}
                                {isUserIgnored(selectedUser) && (
                                    <span className="ml-2 text-sm text-red-500">(Ignored)</span>
                                )}
                            </>
                        ) : (
                            <>
                                <Hash className="w-5 h-5 mr-2"/>
                                {currentRoom}
                            </>
                        )}
                    </h2>
                </div>

                <MessageList
                    messages={getCurrentMessages()}
                    username={user.username}
                    typingUsers={new Set()}
                    messagesEndRef={messagesEndRef}
                />

                <ChatInput
                    message={selectedUser ? privateMessage : message}
                    onMessageChange={e => selectedUser ? setPrivateMessage(e.target.value) : setMessage(e.target.value)}
                    onSendMessage={handleSendMessage}
                    placeholder={`Message ${selectedUser ? '@' + selectedUser : '#' + currentRoom}`}
                    disabled={selectedUser && isUserIgnored(selectedUser)}
                />
            </div>

            <UserSidebar
                onlineUsers={onlineUsers.filter(u => !ignoredUsers.has(u))}
                onUserSelect={handleUserSelect}
                ignoredUsers={ignoredUsers}
                onIgnoreUser={handleIgnoreUser}
                onUnignoreUser={handleUnignoreUser}
            />
        </div>
    );
};

export default ChatInterface;