import React from 'react';

// Comprehensive SEO configuration
const SEO_CONFIG = {
    site: {
        title: 'ChatNexus - Free Indian Chat & Social Messaging Platform',
        shortName: 'ChatNexus',
        domain: 'chatnexus.net',
        baseUrl: 'https://chatnexus.net',
        language: 'en-IN',
        alternateLanguages: ['hi', 'en'],
        region: 'IN',
        type: 'website'
    },
    meta: {
        description: 'ChatNexus is India\'s favorite free social chat platform to make new friends online. Join thousands of Indians chatting right now! Make friends, share moments, and connect with people across India.',
        shortDescription: 'India\'s leading free social chat platform',
        keywords: [
            // Primary keywords
            'indian chat',
            'free chat india',
            'india chat room',
            'desi chat',
            'indian friends online',
            'chat india',
            // Location-based keywords
            'india social chat',
            'delhi chat room',
            'mumbai chat',
            'bangalore chat',
            // Feature-based keywords
            'free online chat',
            'indian chat platform',
            'make friends online',
            'indian social network',
            // Long-tail keywords
            'best indian chat platform',
            'free indian chat rooms',
            'chat with indian friends',
            'indian chat community'
        ].join(', '),
        author: 'ChatNexus Team',
        themeColor: '#4F46E5'
    },
    social: {
        twitter: {
            handle: '@chatnexus',
            cardType: 'summary_large_image'
        },
        facebook: {
            appId: 'YOUR_FB_APP_ID', // Replace with actual FB app ID
            pageId: 'YOUR_FB_PAGE_ID' // Replace with actual FB page ID
        }
    },
    analytics: {
        googleAnalyticsId: 'YOUR_GA_ID', // Replace with actual GA ID
        mediaNetId: 'YOUR_MEDIANET_ID' // Replace with actual Media.net ID
    }
};

// Enhanced SEO setup with structured data
export const setupSEO = () => {
    // Basic meta tags
    const metaTags = [
        // Essential meta tags
        { name: 'description', content: SEO_CONFIG.meta.description },
        { name: 'keywords', content: SEO_CONFIG.meta.keywords },
        { name: 'author', content: SEO_CONFIG.meta.author },
        { name: 'theme-color', content: SEO_CONFIG.meta.themeColor },

        // Open Graph tags
        { property: 'og:title', content: SEO_CONFIG.site.title },
        { property: 'og:description', content: SEO_CONFIG.meta.description },
        { property: 'og:type', content: SEO_CONFIG.site.type },
        { property: 'og:url', content: SEO_CONFIG.site.baseUrl },
        { property: 'og:locale', content: 'en_IN' },
        { property: 'og:site_name', content: SEO_CONFIG.site.shortName },

        // Twitter Card tags
        { name: 'twitter:card', content: SEO_CONFIG.social.twitter.cardType },
        { name: 'twitter:site', content: SEO_CONFIG.social.twitter.handle },
        { name: 'twitter:title', content: SEO_CONFIG.site.title },
        { name: 'twitter:description', content: SEO_CONFIG.meta.description },

        // Geo tags
        { name: 'geo.region', content: SEO_CONFIG.site.region },
        { name: 'geo.placename', content: 'India' },
        { name: 'geo.position', content: '20.5937;78.9629' },
        { name: 'ICBM', content: '20.5937, 78.9629' }
    ];

    // Add meta tags to document head
    metaTags.forEach(tag => {
        const meta = document.createElement('meta');
        Object.entries(tag).forEach(([key, value]) => {
            meta[key] = value;
        });
        document.head.appendChild(meta);
    });

    // Add structured data for better SEO
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'WebApplication',
        name: SEO_CONFIG.site.title,
        description: SEO_CONFIG.meta.description,
        url: SEO_CONFIG.site.baseUrl,
        applicationCategory: 'SocialNetworkingApplication',
        operatingSystem: 'Web',
        offers: {
            '@type': 'Offer',
            price: '0',
            priceCurrency: 'INR'
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.8',
            ratingCount: '10000'
        }
    };

    // Add structured data script to head
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);
};

// Enhanced ad component with better tracking
export const AdPlacement = ({ position = 'default' }) => {
    const adStyles = {
        default: 'w-full my-4 bg-gray-50',
        sidebar: 'w-64 min-h-[600px] hidden lg:block bg-gray-50',
        banner: 'w-full h-[90px] bg-gray-50'
    };

    const adId = `media-net-ad-${Math.random().toString(36).substr(2, 9)}`;

    React.useEffect(() => {
        // Track ad impressions
        if (window.gtag) {
            window.gtag('event', 'ad_impression', {
                'event_category': 'advertising',
                'event_label': position,
                'value': 1
            });
        }
    }, [position]);

    return (
        <div className={`ad-container ${adStyles[position]}`}>
            <div id={adId} className="media-net-ad" />
        </div>
    );
};

// Enhanced ad initialization with error handling
export const initializeAds = () => {
    try {
        // Initialize Media.net
        const mediaNetScript = document.createElement('script');
        mediaNetScript.async = true;
        mediaNetScript.src = `//contextual.media.net/dmedianet.js?cid=${SEO_CONFIG.analytics.mediaNetId}`;
        document.head.appendChild(mediaNetScript);

        // Initialize Google Analytics
        if (SEO_CONFIG.analytics.googleAnalyticsId) {
            const gaScript = document.createElement('script');
            gaScript.async = true;
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${SEO_CONFIG.analytics.googleAnalyticsId}`;
            document.head.appendChild(gaScript);

            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', SEO_CONFIG.analytics.googleAnalyticsId);
        }
    } catch (error) {
        console.error('Error initializing ads:', error);
    }
};

export default SEO_CONFIG;