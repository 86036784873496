import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { UserRegistration } from './components/auth/UserRegistration';
import { ChatInterface } from './components/chat/ChatInterface';
import { setupSEO, initializeAds, AdPlacement } from './services/analytics';

// SEO configuration
const SEO_CONFIG = {
    title: 'ChatNexus - Free Indian Chat & Social Messaging Platform',
    defaultDescription: 'ChatNexus is India\'s favorite free social chat platform to make new friends online. Join thousands of Indians chatting right now! Make friends, share moments, and connect with people across India.',
    baseUrl: 'https://chatnexus.net',
    twitterHandle: '@chatnexus',
    keywords: 'indian chat, india chat room, free chat india, make friends online, desi chat, indian friends, social chat india, chatting app india, free messaging india, indian social network, online chat india, chatnexus, indian chat platform',
    location: 'India',
    language: ['en', 'hi'],
};

function App() {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    const [settings, setSettings] = useState(() =>
            JSON.parse(localStorage.getItem('chatSettings')) || {
                enableSound: true,
                showOnlineStatus: true,
                allowDirectMessages: true,
                allowAds: true
            }
    );

    const [pageTitle, setPageTitle] = useState(SEO_CONFIG.title);
    const [pageDescription, setPageDescription] = useState(SEO_CONFIG.defaultDescription);

    useEffect(() => {
        // Initial setup
        setupSEO();

        // Initialize ads if allowed
        if (settings.allowAds) {
            initializeAds();
        }

        // Update page title based on user status
        if (user) {
            setPageTitle(`${SEO_CONFIG.title} - Chat Dashboard`);
            setPageDescription(`Secure chat dashboard for ${user.username}. Features include real-time messaging, customizable settings, and more.`);
        } else {
            setPageTitle(`${SEO_CONFIG.title} - Sign Up`);
            setPageDescription('Join our secure chat platform. Create an account to start messaging in real-time.');
        }
    }, [settings.allowAds, user]);

    const handleRegistration = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const handleSettingsUpdate = (newSettings) => {
        setSettings(newSettings);
        localStorage.setItem('chatSettings', JSON.stringify(newSettings));
    };

    // Common SEO component
    const SeoHead = () => (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta name="keywords" content={SEO_CONFIG.keywords} />

            {/* Open Graph tags */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={SEO_CONFIG.baseUrl} />
            <meta property="og:locale" content="en_IN" />
            <meta property="og:site_name" content="ChatNexus" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={SEO_CONFIG.twitterHandle} />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />

            {/* Regional and Language tags */}
            <meta name="geo.region" content="IN" />
            <meta name="geo.position" content="20.5937;78.9629" />
            <meta name="ICBM" content="20.5937, 78.9629" />
            <meta name="geo.placename" content="India" />
            <link rel="alternate" href={SEO_CONFIG.baseUrl} hrefLang="en-IN" />
            <link rel="alternate" href={SEO_CONFIG.baseUrl} hrefLang="hi" />

            {/* Additional SEO tags */}
            <link rel="canonical" href={SEO_CONFIG.baseUrl} />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <html lang="en-IN" />
        </Helmet>
    );

    // If user exists, show chat interface
    if (user) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col">
                <SeoHead />
                {/* Top banner ad */}
                {settings.allowAds && (
                    <div className="w-full border-b">
                        <AdPlacement position="banner" />
                    </div>
                )}

                {/* Main chat interface */}
                <div className="flex-1">
                    <ChatInterface
                        user={user}
                        settings={settings}
                        onSettingsUpdate={handleSettingsUpdate}
                    />
                </div>

                {/* Bottom banner ad */}
                {settings.allowAds && (
                    <div className="w-full border-t">
                        <AdPlacement position="banner" />
                    </div>
                )}
            </div>
        );
    }

    // Registration page with top ad only
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col">
            <SeoHead />
            {settings.allowAds && (
                <div className="w-full border-b">
                    <AdPlacement position="banner" />
                </div>
            )}
            <div className="flex-1 flex items-center justify-center p-4">
                <UserRegistration onRegister={handleRegistration} />
            </div>
        </div>
    );
}

export default App;